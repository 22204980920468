import "./Home.css";
import IntroduceImage from "../../assets/IntroduceImage.png";
import WhyIcon1 from "../../assets/WhyIcon1.png";
import WhyIcon2 from "../../assets/WhyIcon2.png";
import WhyIcon3 from "../../assets/WhyIcon3.png";
import TwitterIcon from "../../assets/twitter.svg";
import DiscordIcon from "../../assets/discord.svg";
import GithubIcon from "../../assets/github.svg";
import Logo from "../../assets/logo.png";
import Menu from "../../assets/Menu.svg";
import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import CloseIcon from "../../assets/close.svg";
function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const navScroll = (nav) => {
    const element = document.getElementById(nav);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="Home">
      <div className="Header">
        <img src={Logo} width={140} />
        <ul className="Nav">
          <li>
            <a href="https://docs.ame.network/" target="_blank">
              Learn
            </a>
          </li>

          <li
            onClick={() => {
              navScroll("stacks");
            }}
          >
            Stacks
          </li>
          <li>
            <a href="https://github.com/AmeNetwork/ame" target="_blank">
              Build
            </a>
          </li>
        </ul>

        <div className="MenuButton" onClick={toggleDrawer}>
          <img src={Menu} width={30} />
        </div>
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size="80%"
        overlayOpacity={0.8}
      >
        <div className="Menus">
          <div className="MenuHeader"></div>
          <ul className="MenuItems">
            <li>
              <a href="https://docs.ame.network/" target="_blank">
                Learn
              </a>
            </li>
            <li
              onClick={() => {
                navScroll("stacks");
              }}
            >
              Stacks
            </li>
            <li>
              <a href="https://docs.ame.network/">Build</a>
            </li>
          </ul>
          <div className="CloseMenu" onClick={toggleDrawer}>
            <img src={CloseIcon} width="100%" />
          </div>
        </div>
      </Drawer>

      <div className="Introduce">
        <div className="IntroduceContent">
          <h6> Composable Social Network</h6>
          <p>
            Ame is a composable social network, allowing users to customize and
            combine different social functions and tools according to their
            interests, needs and preferences. This hyperstructure brings diverse
            and rich social experiences to users on the chain.
          </p>
          <a
            href="https://docs.ame.network/"
            target="_blank"
            className="LearnMoreButton"
          >
            Learn More
          </a>
        </div>

        <div className="IntroduceImage">
          <img src={IntroduceImage} width="100%" />
        </div>
      </div>

      <div className="Why" id="about">
        <div className="WhyTitle">Why Ame?</div>

        <ul className="WhyItems">
          <li>
            <div className="WhyItemTitle">
              <img src={WhyIcon1} width={24} />
              <p>Fully Onchain</p>
            </div>
            <div className="WhyItemDetail">
              All logic and rules are implemented through smart contracts, and
              the data is permanently stored on the chain.
            </div>
          </li>
          <li>
            <div className="WhyItemTitle">
              <img src={WhyIcon2} width={24} />
              <p>Composable</p>
            </div>
            <div className="WhyItemDetail">
              Users can combine social components without permission, free and
              open.
            </div>
          </li>
          <li>
            <div className="WhyItemTitle">
              <img src={WhyIcon3} width={24} />
              <p>Low Complexity</p>
            </div>
            <div className="WhyItemDetail">
              Quickly build and customize social features with low-complexity
              interface rules.
            </div>
          </li>
        </ul>
      </div>

      <div className="Tools" id="stacks">
        <ul className="Grid">
          <li className="ToolsTitle">Stacks</li>
          <li className="GridCenter toolkit">
            <h6>Onchain Toolkit</h6>
            <div className="toolkitTitle">
              <a href="https://github.com/AmeNetwork/ame-sdk" target="_blank">
                Ame SDK
              </a>
            </div>
            <p>ame-sdk is a Javascript SDK used to interact with Ame Network.</p>
            <div className="toolkitTitle">
              <a href="https://scan.ame.network/" target="_blank">
                Ame Scan
              </a>
            </div>
            <p>
              Ame Scan is a manager for viewing social components of various
              networks.
            </p>
            <div className="toolkitTitle">
              <a href="https://eips.ethereum.org/EIPS/eip-7654" target="_blank">
                EIP-7654
              </a>
            </div>
            <p>
            EIP-7654 is a composable component standard proposed by Ame Network.
            </p>
          </li>
          <li>
            <h6> Ame Vibe</h6>
            <p>
              This is a proof of contribution for builders. Anyone can
              participate in community building by writing code, publishing
              valuable content, and organizing activities.
            </p>
          </li>
        </ul>
        <div className="GridSplit"></div>
        <ul className="Grid">
          <li>
            <h6>Core Components</h6>
            <p>
              Including personal information, social sharing and other basic
              components.
            </p>
          </li>
          <li className="GridCenter">
            <h6>Ame Kiss</h6>
            <p>
              This is a set of interoperability adapters that connect and
              collaborate with different onchain applications.
            </p>
          </li>
          <li>
            <h6>Try more interesting onchain social experiments</h6>
          </li>
        </ul>
      </div>

      <div className="Build">
        <div className="BuildTitle">Build with Ame</div>
        <div className="BuildDescription">
          Become an early developer, help us build Ame better.
        </div>
        <a
          href="https://docs.ame.network/"
          className="BuildButton"
          target="_blank"
        >
          Start Building
        </a>
        <div className="BuildDescription">Get involved</div>
        <div className="SocialLinks">
          <a
            href="https://twitter.com/0xAmeNetwork"
            target="_blank"
            className="TwitterIcon"
          >
            <img src={TwitterIcon} width="100%" />
          </a>

          <a
            href="https://github.com/AmeNetwork/ame"
            target="_blank"
            className="TwitterIcon"
          >
            <img src={GithubIcon} width="100%" />
          </a>
          {/* <a href="" target="_blank" className="DiscordIcon">
            <img src={DiscordIcon} width="100%" />
          </a> */}
        </div>
      </div>

      <div className="Footer">© 2024 Ame Network</div>
    </div>
  );
}
export default Home;
