import { React, StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@rainbow-me/rainbowkit/styles.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./router";
import reportWebVitals from "./reportWebVitals";
import * as buffer from "buffer";
window.Buffer = buffer.Buffer;






const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RouterProvider router={createBrowserRouter(routes)} />

);

reportWebVitals();
